.connections-window-container {
  left: 2rem;
  top: 2rem;
  width: 300px;
}

.connections-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .body {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .entry {
      width: 100%;
      display: flex;
      gap: 0.25rem;
      font-size: 75%;
      .field {
        flex: 1;
      }
      .values {
        flex: 2;
        display: flex;
        flex-direction: column;
        .connection {
          display: flex;
          justify-content: space-between;
          gap: 0.5rem;
          .success {
            color: var(--success);
          }
          .error {
            color: var(--error);
          }
          .name {
            flex: 1;
          }
        }
      }
    }
  }
}
