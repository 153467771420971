.reference-card-container {
  right: 2rem;
  top: 2rem;
  width: 300px;
}

.draggable-container {
  position: absolute;

  cursor: default;
  .relative-parent {
    position: relative;
    width: 100%;
    height: 100%;

    .draggable-window {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      border-radius: 10px;
      border: 2px solid var(--background-offset);
      padding: 1rem;
      background-color: rgba($color: #000000, $alpha: 0.95);
      box-shadow: 1px 1px 5px black;
      cursor: move;
      user-select: none;

      .title {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .text {
          flex: 1;
          font-size: 250%;
          font-family: 'Alex Brush', cursive;
          text-shadow: 2px 2px 0px rgb(255 0 0 / 0.6);
          text-align: center;
        }
        .controls {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 150%;
          color: red;
          cursor: pointer;
        }
      }

      .turn-options {
        font-size: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .op {
          display: flex;
          align-items: flex-start;
          gap: 0.5rem;
          b {
            color: red;
          }
        }
      }
    }
  }
}
