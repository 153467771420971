.dialogue {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .dialogue-internal {
    position: relative;
    border-radius: 4px;
    transition: all 250ms ease;
    box-shadow: 0px 3px 5px var(--contrast);
    .dialogue-content {
      max-width: min(600px, 95vw);
      max-height: 90vh;
      display: flex;
      flex-direction: column;
      background-color: var(--background);
      color: var(--contrast);
      padding: 2rem;
      gap: 1rem;
      z-index: 2;
      border-radius: inherit;

      .body {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        overflow-y: auto;

        b {
          color: red;
        }

        h1 {
          font-family: 'Alex Brush', cursive;
          font-weight: normal;
          margin: 0;
          overflow: visible;
          font-size: 450%;
          text-shadow: 4px 4px 0px rgb(255 0 0 / 0.3);
        }

        .rules-list {
          display: flex;
          flex-direction: column;
          margin: 0;
          padding: 1rem;
          gap: 0.5rem;
          .rl-item {
            padding-left: 1rem;
            svg {
              margin-right: 1rem;
            }
          }
        }
      }
      .footer {
      }
      .dialogue-controls {
        button {
          --button-color: var(--background);
        }
      }
    }
  }
}

.dialogue-section {
  color: var(--text);
  display: flex;
  flex-direction: column;
  width: 100%;
}

.dialogue-section.col {
  flex-direction: column;
}

.dialogue-controls {
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
}

.fade-in {
  animation: fadeIn var(--fade-duration) ease forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}

.fade-out {
  animation: fadeOut var(--fade-duration) ease forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#join-game-dialogue {
  .dialogue-content {
    color: var(--text);
    border: 4px solid var(--text);
  }
  button {
    --button-color: var(--background);
  }
  input[type='text'] {
    padding: 1rem;
    border: 2px solid var(--text);
  }
}

#landing-dialogue {
  button {
    --button-color: var(--background);
    font-size: 1.5rem;
  }
  .dialogue-content {
    width: min(600px, 100vw);
  }
  .permission-settings {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // align-items: center;
    .permission-setting {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      align-items: center;
      position: relative;
      label {
        height: 2rem;
      }
      input {
        width: 100%;
        padding: 0;
        margin: 0;
        background-color: transparent;
      }
      .tracker-bg {
        position: absolute;
        left: 0;
        top: calc(2rem + 1px);
        height: 2rem;
        background-color: rgba(255, 0, 0, 0.5);
        pointer-events: none;
        transition: all 100ms ease;
      }
    }
  }
  .dialogue-internal {
    box-shadow: 0px 0px 800px lightgray;
  }
  .title {
    text-transform: uppercase;
    font-size: 250%;
    // font-weight: bold;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
    letter-spacing: 5px;
    color: var(--contrast);
    border-bottom: 1px solid darkred;
    // font-family: 'Alex Brush', cursive;
    background-image: linear-gradient(to right, darkred, transparent);
  }
  .cookie-disabled-warning {
    color: red;
    text-align: center;
  }
}

#settings-dialogue {
  .dialogue-content {
    width: min(600px, 100vw);
  }
  .title {
    text-transform: uppercase;
    font-size: 250%;
    // font-weight: bold;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
    letter-spacing: 5px;
    color: var(--background);
    border-bottom: 1px solid darkred;
    // font-family: 'Alex Brush', cursive;
    background-image: linear-gradient(to right, darkred, transparent);
  }
}

.settings-group {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  .setting {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    position: relative;
    label {
      height: 2rem;
    }
    input {
      width: 100%;
      padding: 0;
      margin: 0;
      background-color: transparent;
    }
    .tracker-bg {
      position: absolute;
      left: 0;
      top: calc(2rem + 1px);
      height: 2rem;
      background-color: rgba(255, 0, 0, 0.5);
      pointer-events: none;
      transition: all 100ms ease;
    }
  }
}

.mobile-warning-dialogue * {
  color: var(--text);
}

#rules-dialogue {
  .dialogue-section {
    color: var(--text);
    padding-right: 1rem;
    margin-bottom: 2rem;
    .title {
      text-transform: uppercase;
      font-size: 250%;
      // font-weight: bold;
      padding-left: 0.5rem;
      margin-bottom: 1rem;
      letter-spacing: 5px;
      color: var(--background);
      border-bottom: 1px solid darkred;
      // font-family: 'Alex Brush', cursive;
      background-image: linear-gradient(to right, darkred, transparent);
    }
    .blurb {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding-left: 1rem;
      border-left: 1px solid var(--text);

      h2 {
        margin: 0;
        letter-spacing: 1px;
      }
    }
  }
  .separator {
    border-bottom: 1px solid darkred;
    width: calc(100% - 1rem);
  }
  button {
    --button-color: var(--background);
  }
  .dialogue-content {
    max-width: min(800px, 95vw);
  }
  .body {
    gap: 0rem;
  }
  .dialogue-section:first-of-type {
    margin-bottom: 0;
  }
  .dialogue-controls {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    justify-content: flex-end;
  }
}

#end-round-dialogue {
  background-color: rgba(0, 0, 0, 0.25);
  text-align: center;
}

.credits {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  font-weight: bold;

  a {
    color: red;
  }
}
