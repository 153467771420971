.card-zone {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--text-half-opacity);
  padding: 0.5rem;
  gap: 0.5rem;
  height: 100%;

  .card-location {
    position: relative;
    display: flex;
    --card-height: 15vh;
    height: var(--card-height);
    width: calc(var(--card-height) * 4 / 7);
    border-radius: 14% / 8%;
    .card-outline {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      border: 2px dotted var(--text-half-opacity);
      border-radius: 14% / 8%;
    }
    .card-outline:hover {
      cursor: pointer;
      background-color: #ffffff55;
    }
    .card-container {
      z-index: 2;
      width: 100%;
    }
  }
  .can-play {
    .card-outline {
      background-color: #ffffff22;
    }
  }
}
