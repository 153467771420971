.tutorial-container {
  width: 100%;
  height: 100%;
  position: absolute;
  font-family: 'Xanh Mono', monospace;
}
.tutorial-mask {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  position: absolute;
  display: flex;
  .tutorial-message-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    gap: 2rem;
    align-items: center;
    background-image: linear-gradient(transparent, black 40%, black 60%, transparent);
    padding: 4rem;
    .big-a {
      color: red;
      font-size: 1750%;
      z-index: 2;
      translate: 0 2rem;
    }
    .messages {
      .message {
        opacity: 0;
        font-family: 'Xanh Mono', monospace;
        font-size: 175%;
        padding: 2rem;
        animation-name: TutorialMessageIn;
        animation-duration: 1000ms;
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    }
  }
}

.control-group {
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  .tutorial-control {
    pointer-events: all;
    cursor: pointer;
    opacity: 0;
    position: absolute;
    font-size: 400%;
    color: red;
    text-shadow: 1px 1px 5px black;
    animation-name: NextIconPulse;
    animation-duration: 1500ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .next {
    right: 2rem;
    bottom: 2rem;
  }

  .prev {
    left: 2rem;
    bottom: 2rem;
  }
}

@keyframes NextIconPulse {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes TutorialMessageIn {
  0% {
    opacity: 0;
    translate: 0 20px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}

.lens-group {
  pointer-events: none;
  #highlighting-lens {
    transition: all 500ms ease;
    position: absolute;
    border-style: solid;
    border-color: darkred;
    border-radius: 4px;
    z-index: 3;
    box-shadow: 0px 0px 8px black;
  }

  #hl-left,
  #hl-right,
  #hl-bot,
  #hl-top {
    transition: all 500ms ease;
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.85);
    pointer-events: all;
    // box-shadow: 0px 0px 8px black;
  }
  .lens-message-container {
    z-index: 3;
    position: absolute;
    .lens-message {
      width: 300px;
      height: 100%;
      padding: 0rem 1rem;
    }
  }
}

.red-true {
  #hl-left,
  #hl-right,
  #hl-bot,
  #hl-top {
    background-color: rgba(45, 0, 0, 0.85);
  }
}

.exit-group {
  color: red;
  position: absolute;
  left: 1rem;
  top: 1rem;
  z-index: 5;
  button {
    color: red;
    font-size: 150%;
    opacity: 0.25;
    cursor: pointer;
    transition: all 100ms ease;
  }

  button:hover {
    opacity: 0.5;
  }
}
