.hero-anim-container {
  // display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.hero-anim-container > * {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .hero-anim {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .anim-layer {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .box {
        position: relative;
        width: 100%;
        height: 0px;
        background-color: rgba(#000, 0.95);
      }
      .box.anim-waking {
        animation-name: BoxIn;
        animation-duration: var(--wake-duration);
        animation-timing-function: ease;
        animation-delay: var(--wake-delay);
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
      .box.anim-idle {
        height: 200px;
      }
      .box.anim-snoozing {
        height: 200px;
        animation-name: BoxOut;
        animation-duration: var(--snooze-duration);
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }

      .text {
        position: relative;
        font-size: 500%;
        color: var(--text);
        z-index: 2;
        font-family: 'Alex Brush', cursive;
        opacity: 0;
        text-align: center;
      }
      .text.anim-waking {
        animation-name: TextIn;
        animation-duration: var(--wake-duration);
        animation-timing-function: ease;
        animation-delay: var(--wake-delay);
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
      .text.anim-idle {
        opacity: 1;
        text-shadow: 5px 5px 1px rgb(255 0 0 / 0.6);
      }
      .text.anim-snoozing {
        animation-name: TextOut;
        animation-duration: var(--snooze-duration);
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }

      .screen {
        opacity: 0;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 1);
      }
      .screen.anim-waking {
        animation-name: ScreenIn;
        animation-duration: var(--wake-duration);
        animation-timing-function: ease;
        animation-delay: var(--wake-delay);
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
      .screen.anim-idle {
        opacity: 1;
      }
      .screen.anim-snoozing {
        opacity: 1;
        animation-name: ScreenOut;
        animation-duration: var(--snooze-duration);
        animation-timing-function: ease;
        animation-iteration-count: 1;
        animation-direction: normal;
        animation-fill-mode: forwards;
      }
    }
  }
}

@keyframes ScreenIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ScreenOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes BoxIn {
  0% {
    height: 0px;
  }
  100% {
    height: 200px;
  }
}

@keyframes BoxOut {
  0% {
    height: 200px;
  }
  100% {
    height: 0px;
  }
}

@keyframes TextIn {
  0% {
    opacity: 0;
    text-shadow: 0px 0px 0px rgb(255 0 0 / 0.6);
    translate: 0px 50px;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    translate: 0px 0px;
  }
  100% {
    opacity: 1;
    text-shadow: 5px 5px 1px rgb(255 0 0 / 0.6);
  }
}

@keyframes TextOut {
  100% {
    opacity: 0;
    text-shadow: 0px 0px 0px rgb(255 0 0 / 0.6);
    translate: 0px 50px;
  }
  75% {
    opacity: 0;
    translate: 0px 50px;
  }
  50% {
    opacity: 0;
    translate: 0px 0px;
  }
  0% {
    opacity: 1;
    text-shadow: 5px 5px 1px rgb(255 0 0 / 0.6);
  }
}
