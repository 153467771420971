.log-window-container {
  right: 2rem;
  bottom: 2rem;
  width: 300px;
  .header {
    display: flex;
    justify-content: flex-end;
    font-weight: bold;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid darkred;
    .close {
      cursor: pointer;
      color: red;
    }
  }
  .body {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

.log-list::-webkit-scrollbar-thumb {
  background-color: #ffffff50;
}

.log-list {
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 0.5rem;
  padding-bottom: 0.5rem;
  *:not(.scroll-anchor) {
    overflow-anchor: none;
  }
  .log-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.25rem;
    .sourcetime {
      display: flex;
      gap: 0.25rem;
      font-size: 65%;
      .source {
        font-weight: bold;
      }
      .source.Game {
        color: red;
      }
      .time {
      }
    }
    .content {
      flex: 3;
      font-size: 90%;
    }
  }
}

.chat-input {
  width: 100%;
  display: flex;
  input {
    flex: 1;
    padding: 0.5rem;
    border: none;
    outline: none;
    background-color: var(--text);
    color: var(--background);
  }
  input:active {
    outline: none;
    border: none;
  }
}
