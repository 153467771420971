.popout-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
  transition: all 200ms ease;
  background-color: var(--button-color);
  color: var(--text);
  border: 2px solid var(--text);
  font-family: 'Xanh Mono', monospace;
}

.popout-button.rounded-square {
  border-radius: 4px;
}

.popout-button:hover {
  background-color: var(--text);
  color: var(--button-color);
  transform: translate(-2px, -2px);
  box-shadow: 1px 1px 10px var(--background);
}

.popout-button:active {
  transform: translate(0, 0);
  box-shadow: none;
  opacity: 0.5;
}
