* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background: #222;
  --background-offset: #444;
  --contrast: #111;
  --text: #d6d3c1;
  --text-half-opacity: #d6d3c17f;
  --text-dark: #c4c0a7;
  --blue-background: #142044;
  --purple-background: #301934;
  --red-background: #450000;
  // Generics
  --error: #840505;
  --error-opacity-low: #84050542;
  --error-opacity-lowest: #84050526;
  --success: #82c346;
  --success-opacity-low: #82c34642;
  --success-opacity-lowest: #82c34626;
  --info: #2b0584;
  --info-opacity-low: #2b058442;
  --info-opacity-lowest: #2b058426;
  --warning: #d6871f;
  --warning-opacity-low: #d6871f42;
  --warning-opacity-lowest: #d6871f26;
  --special: var(--info);
  background-color: var(--background);
  color: var(--text);
  --box-shadow: 2px 2px 10px black;
  --glyphs-width: 15%;
  // Global rendering effects
  filter: grayscale(10%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  padding: 0;
  margin: 0;
}

.nodisplay {
  display: none !important;
}

.wait {
  cursor: wait !important;
}

.nomouse {
  pointer-events: none;
}

.green {
  color: green;
}

.anim-paused,
.anim-paused * {
  animation-play-state: paused !important;
}

button {
  background-color: transparent;
  border: none;
  outline: none;
}

input[type='text'] {
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--text);
}

.relative {
  position: relative;
}

/* Note: Does not work in Firefox */

::-webkit-scrollbar {
  cursor: pointer;
  width: 0.5rem;
  height: 0.5rem;
  margin: 0.5rem 0rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #00000050;
  border-radius: 0.2rem;
}

.scroll-anchor {
  overflow-anchor: auto;
  height: 1px;
  width: 100%;
}

// TODO, move the below:

.app-container {
  position: relative;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.app-bg {
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .bg-icon-row.moving {
    animation-name: SideScroll;
    animation-timing-function: ease;
    animation-duration: var(--anim-duration);
    animation-iteration-count: infinite;
    animation-delay: var(--anim-delay);
    animation-direction: var(--anim-direction);
  }

  .bg-icon-row {
    display: flex;
    width: fit-content;

    .bg-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: var(--icon-size);
      height: var(--icon-size);
      font-size: 200%;
      border-left: 2px solid black;
    }
    .bg-icon:hover {
      scale: 1.5;
    }
  }
  .raised {
    box-shadow: 0px 0px 15px black;
  }
}

@keyframes SideScroll {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100vw);
  }
  100% {
    transform: translateX(0);
  }
}

.App {
  z-index: 2;
  display: flex;
  width: 100%;
  height: 100%;
  font-family: 'Xanh Mono', monospace;
  // font-family: 'Alex Brush', cursive;
  // font-family: 'Parisienne', cursive;
  // font-family: 'Tangerine', cursive;
}

.fancy {
  text-shadow: 1px 1px 5px black;
  font-family: 'Alex Brush', cursive;
}

.table {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #142044fa;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.table > * {
  z-index: 1;
}

.table::after {
  z-index: 0;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/felt.png');
  opacity: 0.05;
}
