.complex-icon {
  width: 100%;
  height: 100%;
  position: relative;

  .layer {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
  }

  .solo {
    justify-content: center;
    align-items: center;
    font-size: 100%;
  }

  .four-by-four {
    font-size: 50%;
  }

  .nine-by-nine {
    font-size: 33.3%;
  }

  .row {
    flex: 1;
    display: flex;
    .cell {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
