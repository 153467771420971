.lobby {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #301934f0;
  // font-family: 'Tangerine', cursive;
  font-family: 'Xanh Mono', monospace;

  .lobby-title {
    font-size: 750%;
    text-shadow: 4px 4px 0px black;
  }

  .lobby-code {
    font-family: 'Xanh Mono', monospace;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
    #copy-join-button {
      cursor: pointer;
      font-size: 150%;
    }
  }

  .lobby-players {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    width: min(800px, 100vw);
    border: 2px solid var(--text);
    overflow: auto;
    .lobby-user {
      background-color: #000000f0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 100%;
      padding: 1rem 2rem;
      border-top: 2px solid var(--text);

      input {
        font-size: inherit;
        font-family: 'Xanh Mono', monospace;
        background-color: #ffffff20;
        text-align: center;
        border: 2px solid var(--text);
      }

      .name {
        width: 50%;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-shadow: 1px 1px 5px black;
      }
      .user-pfp {
        font-size: 200%;
        cursor: pointer;
        position: relative;
        height: 100%;
        aspect-ratio: 1 / 1;
        .delete-icon {
          left: 0;
          top: 0;
          position: absolute;
          width: 100%;
          height: 100%;
          opacity: 0;
          transition: all 200ms ease;
          z-index: 2;
          display: flex;
          justify-content: center;
          align-items: center;
          color: darkred;
        }
        .delete-icon:hover {
          opacity: 1;
        }
      }
    }
    .lobby-user:first-of-type {
      border-top: none;
    }
  }

  .awaiting {
    font-size: 250%;
    padding: 1rem;
  }

  .controls {
    padding: 1rem;
    display: flex;
    justify-content: center;
    gap: 1rem;

    button {
      --button-color: #301934f0;
      display: flex;
      gap: 1rem;
      font-weight: bold;
    }

    .awaiting-players {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      transition: all 200ms ease;
      // background-color: var(--button-color);
      background-color: #301934f0;
      color: var(--text);
      border: 2px solid var(--text);
      border-radius: 4px;
      font-family: 'Xanh Mono', monospace;
      font-weight: bold;
      font-size: 0.8rem;
    }
  }

  .dealer {
    height: 100%;
    aspect-ratio: 1 / 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 100ms ease;
    font-size: 200%;
  }
  .dealer.active {
    color: goldenrod;
  }
}
