.Landing {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #6b0000b0;
  // background-image: linear-gradient(to right, #6b000040, #6b0000f0 25%, #6b0000f0 75%, #6b000040);
  // font-family: 'Tangerine', cursive;
  font-family: 'Alex Brush', cursive;
  // text-shadow:
  //   1px 1px 5px black,
  //   1px 1px 25px black,
  //   1px 1px 55px black;
  // text-shadow: 1px 1px 5px black;
  text-shadow: 4px 4px 0px black;

  .game-title {
    font-size: 1000%;
    text-shadow: 4px 4px 0px black;
    cursor: default;
  }

  .menu-op {
    position: relative;
    cursor: pointer;
    font-size: 250%;
    transition: all 200ms ease;
    .op-text {
      position: relative;
      z-index: 2;
      transition: all 50ms ease;
    }
    .op-bg-wrapper {
      z-index: 1;
      position: absolute;
      height: 100%;
      width: 200%;
      left: -50%;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .op-suit {
        color: rgba(#000, 0.6);
        text-shadow: none;
      }
      .op-suit:first-of-type {
        translate: 10%;
      }
      .op-suit:last-of-type {
        translate: -10%;
      }
      .op-line {
        width: 200%;
        height: 3px;
        background-color: rgba(#000, 0.5);
      }
    }
  }

  .menu-op:hover {
    text-shadow:
      1px 1px 5px black,
      1px 1px 25px black,
      1px 1px 55px black;
    color: darkred;
    scale: 1.2;
    .op-suit {
      color: rgba(#000, 1);
    }
    .op-line {
      background-color: rgba(#000, 0.95);
    }
  }
  .menu-op:active {
    color: #000;
  }
}

.Landing * {
  z-index: 1;
}

.favicon-gen {
  position: fixed;
  height: 95vh;
  width: 95vh;
  background-color: var(--text);
  color: darkred;
  font-size: 3000%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-shadow: none;
  .rel {
    position: relative;
    width: 100%;
    height: 100%;
    * {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .spade {
      font-size: 150%;
    }
    .A {
      font-size: 75%;
      color: rgba(#000, 0.5);
    }
  }
}
