.exit-up {
  animation-name: ExitUp;
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes ExitUp {
  0% {
    opacity: 1;
    // transform: translateY(0);
  }
  100% {
    opacity: 0;
    // transform: translateY(-100%);
  }
}

.enter-up {
  animation-name: EnterUp;
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes EnterUp {
  0% {
    opacity: 0;
    // transform: translateY(100%);
  }
  100% {
    opacity: 1;
    // transform: translateY(0);
  }
}

.card-trick-1 {
  animation-name: ct1;
  animation-duration: 750ms;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes ct1 {
  0% {
    scale: 1;
    rotate: 0deg;
  }
  25% {
    scale: 1.3;
    box-shadow: 0px 0px 20px black;
  }
  50% {
    scale: 1;
    rotate: 180deg;
    box-shadow: initial;
  }
  100% {
    scale: 1;
    rotate: 180deg;
    box-shadow: initial;
  }
}

.card-trick-2 {
  animation-name: ct2;
  animation-duration: 750ms;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes ct2 {
  0% {
    scale: 1;
    rotate: 180deg;
  }
  25% {
    scale: 1.3;
    box-shadow: 0px 0px 20px black;
  }
  50% {
    scale: 1;
    rotate: 360deg;
    box-shadow: initial;
  }
  100% {
    scale: 1;
    rotate: 360deg;
    box-shadow: initial;
  }
}

.card-trick-rainbow {
  animation-name: ct_rainbow;
  animation-duration: 1000ms;
  animation-timing-function: ease-out;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes ct_rainbow {
  10% {
    scale: 1.1;
  }
  100% {
    filter: hue-rotate(360deg);
    scale: 1;
  }
}

.quake {
  animation-name: Quake;
  animation-duration: 500ms;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}

@keyframes Quake {
  0% {
    transform: translate(0px, 0px);
  }

  20% {
    transform: translate(5px, 0px);
  }

  40% {
    transform: translate(-5px, 0px);
  }

  60% {
    transform: translate(2px, 0px);
  }

  80% {
    transform: translate(-2px, 0px);
  }

  100% {
    transform: translate(0px, 0px);
  }
}

.tremble {
  animation-name: Quake;
  animation-duration: 100ms;
  animation-timing-function: ease;
  animation-delay: 0;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
