.film-grain {
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png');
  height: 300%;
  width: 300%;
  position: absolute;
  animation: animateGrain 8s steps(10) infinite;
  pointer-events: none;
  scale: 1;
  overflow: hidden;
  transition: all 2500ms ease;
}

@keyframes animateGrain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, -20%);
  }
  30% {
    transform: translate(-5%, -10%);
  }
  40% {
    transform: translate(-15%, -20%);
  }

  50% {
    transform: translate(-5%, -10%);
  }
  60% {
    transform: translate(-15%, -20%);
  }
  70% {
    transform: translate(-5%, -10%);
  }
  80% {
    transform: translate(-15%, -20%);
  }
  90% {
    transform: translate(-5%, -10%);
  }
  100% {
    transform: translate(-15%, -20%);
  }
}
