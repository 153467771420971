.context-container {
  position: fixed;
  background-color: var(--text);
  color: var(--contrast);
  display: flex;
  flex-direction: column;
  box-shadow: 1px 1px 5px black;
  border-radius: 4px;
  overflow: hidden;
  .context-option {
    cursor: pointer;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px dashed gray;
    transition: all 100ms ease;
  }
  .context-option p {
    margin: 0;
    transition: all 100ms ease;
  }
  .context-option:first-of-type {
    border-top: none;
  }
  .context-option:hover {
    background-color: var(--text-dark);
  }
  .context-option:active p {
    scale: 0.9;
  }
  .context-option.disabled {
    text-decoration: line-through;
  }
}

.context-fade-in {
  animation-name: ContextFadeIn;
  animation-duration: var(--fade-duration);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

.context-fade-out {
  animation: ContextFadeOut;
  animation-duration: var(--fade-duration);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}

@keyframes ContextFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes ContextFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
