.card {
  aspect-ratio: 4 / 7;
  height: 100%;
}

.card-content {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--text);
  border-radius: 14% / 8%;
  transition: all 200ms ease;
  transform: rotateY(0deg);
  transform-style: preserve-3d;
  box-shadow: 0px 0px 2px black;
}

.selected-card .card-content::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  border: 4px dashed goldenrod;
  box-sizing: border-box;
}

.card-material {
  position: absolute;
  pointer-events: none;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-image: url(../../../public/az-subtle.png);
  opacity: 0.35;
}

.card.flipped .card-content {
  transform: rotateY(180deg);
}

.card-typography {
  font-size: 250%;
}

.card.black {
  color: var(--background);
}

.card.red {
  --card-color: darkred;
  color: var(--card-color);
}

.suit {
  font-size: 150%;
}

.card-face {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateX(0deg);
}

.card-back {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

// .card.flipped .card-face {
//   z-index: -1;
// }

// .card:not(.flipped) .card-back {
//   z-index: -1;
// }

.card-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.glyph-layer {
  justify-content: space-between;
}

.numeral-glyph {
}

.card-glyphs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 4%;
  padding-left: 4%;
  width: var(--glyphs-width);
}

.card-glyphs.inverse {
  rotate: 180deg;
}

.card-numerals,
.card-portrait {
  width: 100%;
  height: 100%;
  display: flex;
}

.portrait-container,
.numerals-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 6% calc(var(--glyphs-width) + 4%);
}

.portrait-container {
  justify-content: center;
  align-items: center;
  font-size: 1050%;
  font-weight: bold;
}

.portrait-suit {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 1400%;
  opacity: 0.25;
}

.numeral {
  font-size: 400%;
  display: flex;
  justify-content: center;
  align-items: center;
  // outline: 1px dashed black;
  height: 25%;
}

.numerals-container-ace {
  justify-content: center;
  align-items: center;
  .numeral {
    font-size: 750%;
  }
}

.numerals-container-two {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .numeral {
    font-size: 400%;
  }
  .numeral:last-of-type {
    rotate: 180deg;
  }
}

.numerals-container-three {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .numeral:last-of-type {
    rotate: 180deg;
  }
}

.numerals-container-four {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30%;
  column-gap: 33.3%;
  .numeral {
    width: 33.3%;
  }
  .numeral:nth-of-type(2n) {
    rotate: 180deg;
  }
}

.numerals-container-five {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  .numeral {
    width: 33.3%;
  }

  .numeral:nth-of-type(3) {
    height: 100%;
  }

  .numeral:nth-last-of-type(1),
  .numeral:nth-last-of-type(4) {
    rotate: 180deg;
  }
}

.numerals-container-six {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10%;
  column-gap: 33.3%;
  .numeral {
    width: 33.3%;
  }
  .numeral:nth-of-type(3n) {
    rotate: 180deg;
  }
}

.numerals-container-seven {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  .numeral {
    width: 33.3%;
  }

  .numeral:nth-of-type(4) {
    height: 100%;
    padding-bottom: 100%;
  }

  .numeral:nth-last-of-type(1),
  .numeral:nth-last-of-type(5) {
    rotate: 180deg;
  }
}

.numerals-container-eight {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 10%;
  .numeral {
    height: 25%;
    width: 33.3%;
  }
  .numeral:nth-of-type(4) {
    margin-top: 48%;
  }

  .numeral:nth-of-type(5) {
    rotate: 180deg;
    margin-bottom: 48%;
  }
  .numeral:nth-of-type(3),
  .numeral:nth-of-type(8) {
    rotate: 180deg;
  }
}

.numerals-container-nine {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  .numeral {
    width: 33.3%;
  }
  .numeral:nth-of-type(5) {
    height: 100%;
  }
  .numeral:nth-of-type(3),
  .numeral:nth-of-type(4),
  .numeral:nth-of-type(8),
  .numeral:nth-of-type(9) {
    rotate: 180deg;
  }
}

.numerals-container-ten {
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  .numeral {
    height: 25%;
    width: 33.3%;
  }
  .numeral:nth-of-type(5) {
    margin-top: 35%;
  }

  .numeral:nth-of-type(6) {
    rotate: 180deg;
    margin-bottom: 35%;
  }

  .numeral:nth-of-type(3),
  .numeral:nth-of-type(4),
  .numeral:nth-of-type(9),
  .numeral:nth-of-type(10) {
    rotate: 180deg;
  }
}

.suit-icon {
  // outline: 1px dashed blue;
}

.back-design {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 14% / 8%;
  background-color: darkred;

  .artwork {
    width: 75%;
    height: 80%;
    border-radius: 8px;
    z-index: 2;
    background-color: #f7e6e6;
    opacity: 1;
    background:
      linear-gradient(135deg, #b92f2f55 25%, transparent 25%) -30px 0/ 60px 60px,
      linear-gradient(225deg, #b92f2f 25%, transparent 25%) -30px 0/ 60px 60px,
      linear-gradient(315deg, #b92f2f55 25%, transparent 25%) 0px 0/ 60px 60px,
      linear-gradient(45deg, #b92f2f 25%, #f7e6e6 25%) 0px 0/ 60px 60px;
  }
}

.back-design img {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  filter: sepia(1);
}
