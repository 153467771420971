.assassins-table {
  .zone-name {
    cursor: default;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
    padding: 0.5rem;
  }

  .turn-counter {
    pointer-events: none;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    padding: 2rem;
    z-index: 2;
    text-shadow:
      0px 0px 2px black,
      0px 0px 5px black,
      0px 0px 25px black,
      0px 0px 55px black;
    .turn-title {
      font-size: 250%;
      font-family: 'Alex Brush', cursive;
    }
  }

  .opponents-area {
    max-width: 100%;
    height: 25%;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    padding: 0rem 8rem;

    .opponent {
      position: relative;
      padding: 1rem;
      padding-top: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // border: 1px solid var(--text);
      border-top: none;
      overflow: visible;

      .opponent-content {
        display: flex;
      }

      .opponent-hand {
        width: 15vh;
        position: relative;
        .card-container {
          width: 50%;
          position: absolute;
          transition: all 400ms ease;
        }
      }

      .opponent-played {
        .card {
          animation-name: OpponentPlayedIn;
          animation-duration: var(--played-card-in-duration);
          animation-timing-function: ease;
          animation-fill-mode: forwards;
          animation-iteration-count: 1;
        }
      }
    }

    .opponent-name {
      cursor: default;
      padding: 0.5rem;
      height: 25%;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .opp-win-count {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        color: goldenrod;
      }
    }
  }

  .common-area {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex: 25%;

    .protection {
      display: flex;
      flex-direction: column;
      align-items: center;
      .card-zone {
        width: fit-content;
      }
      .card {
        animation-name: BeingPlayedIn;
        animation-duration: var(--played-card-in-duration);
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
    }

    .deck {
      display: flex;
      flex-direction: column;
      align-items: center;
      .card-zone {
        justify-content: flex-start;
        gap: 0;
        width: calc((15vh * 4 / 7) + 1px * var(--ncards));
        border: none;
        box-sizing: content-box;
        .card-location {
          width: 1px;
          cursor: pointer;
        }
      }
    }

    .target {
      display: flex;
      flex-direction: column;
      .card-zone {
        width: fit-content;
      }
    }

    .game-values {
      cursor: default;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      font-size: 200%;
      padding: 0.5rem;
      gap: 0.5rem;
      .game-val {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
      }
      .win-count-val {
        font-size: 65%;
        color: goldenrod;
      }
    }
  }

  .player-area {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
  }

  .active-cards {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50%;
    padding: 1rem;
    position: relative;

    .fresh {
      .card {
        animation-name: BeingPlayedIn;
        animation-duration: var(--played-card-in-duration);
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
      }
    }
  }

  .death-overlay {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #8b000080;
    color: #8b0000;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 500%;
    font-family: 'Alex Brush', cursive;
    text-shadow:
      1px 1px 5px black,
      1px 1px 25px black,
      1px 1px 55px black;
    animation-name: OverlayIn;
    animation-duration: 1000ms;
    animation-delay: 1000ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .win-overlay {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #daa52080;
    color: #daa520;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 500%;
    font-family: 'Alex Brush', cursive;
    text-shadow:
      1px 1px 5px black,
      1px 1px 25px black,
      1px 1px 55px black;
    animation-name: OverlayIn;
    animation-duration: 1000ms;
    animation-delay: 1000ms;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
  }

  .hand {
    z-index: 10;
    // display: flex;
    // justify-content: center;
    // align-items: start;
    // gap: 20px;
    width: 100%;
    height: 50%;
    overflow: visible;
    position: relative;

    .card-container {
      min-width: 200px;
      width: 10%;
      position: absolute;
      transition: all 400ms ease;
    }

    .card {
      animation-name: CardDraw;
      animation-duration: var(--played-card-out-duration);
      animation-timing-function: ease;
      animation-fill-mode: forwards;
      animation-iteration-count: 1;
    }

    .card:hover {
      .card-content {
        translate: -2px -80px;
        box-shadow: var(--box-shadow);
      }
    }

    .selected-card {
      .card-content {
        translate: -2px -80px;
        box-shadow: var(--box-shadow);
      }
    }
  }
}

.action-text-container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  .action-text {
    color: red;
    font-size: 300%;
    animation-name: ActionTextFade;
    animation-duration: var(--action-text-duration);
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    text-shadow: 1px 1px 3px black;
  }
}

.being-played {
  animation-name: BeingPlayedOut;
  animation-duration: var(--played-card-out-duration);
  animation-timing-function: ease;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
}

.card-zone {
  transition: all 400ms ease;
}

@keyframes BeingPlayedOut {
  0% {
    translate: 0 0;
  }
  100% {
    translate: 0 100%;
  }
}

@keyframes CardDraw {
  0% {
    translate: 0 100%;
  }
  100% {
    translate: 0 0;
  }
}

@keyframes BeingPlayedIn {
  0% {
    translate: 0 50vw;
  }
  100% {
    translate: 0 0;
  }
}

@keyframes OpponentPlayedIn {
  0% {
    translate: 0 -50vw;
  }
  100% {
    translate: 0 0;
  }
}

@keyframes ActionTextFade {
  0% {
    opacity: 0;
    translate: 2px 10px;
  }

  25% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    translate: -2px -10px;
    letter-spacing: 5px;
  }
}

@keyframes OverlayIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.purloin-val {
  font-size: 65%;
}

.purloin-true {
  color: var(--success);
}

.purloin-false {
  color: var(--error);
}
