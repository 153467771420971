.sequential-text-wrapper {
  line-height: 125%;
  text-shadow: 0px 0px 5px black;
  .word {
    display: inline-block;
    opacity: 0;
    animation: SequentialWordIn 500ms ease var(--seq-word-delay) 1 forwards;
  }
}

@keyframes SequentialWordIn {
  0% {
    opacity: 0;
    translate: 0 10px;
  }
  100% {
    opacity: 1;
    translate: 0 0;
  }
}
