.floating-menu-container {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  .floating-menu {
    padding: 2rem;
    display: flex;
    gap: 1.5rem;

    .op {
      width: 40px;
      height: 40px;
      transition: all 200ms ease;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 200%;
      color: var(--text);
      text-shadow: 0px 0px 5px white;

      svg {
        filter: drop-shadow(0px 0px 1px rgb(0 0 0 / 0.8));
      }
    }
    .op:hover {
      scale: 1.1;
    }
    .op:active {
      scale: 0.9;
    }
  }
}
