.tooltip-container {
  pointer-events: none;
  position: fixed;
  display: flex;
  white-space: nowrap;
}

.tooltip-wrapper {
  border-radius: 4px;
  width: auto;
  background-color: var(--contrast);
  text-align: center;
  color: var(--text);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  font-size: 0.75rem;
  box-shadow: 2px 2px 4px var(--contrast);
  border: 1px solid var(--background-offset);
}

.container-above {
  height: 0;
  width: var(--container-width);
  justify-content: center;
  align-items: end;
  overflow: visible;
  left: var(--container-left);
  top: var(--container-top);
}

.container-below {
  height: 0;
  width: var(--container-width);
  justify-content: center;
  align-items: start;
  overflow: visible;
  left: var(--container-left);
  top: var(--container-top);
}

.container-left {
  height: var(--container-height);
  width: 0;
  align-items: center;
  justify-content: end;
  overflow: visible;
  left: var(--container-left);
  top: var(--container-top);
}

.container-right {
  height: var(--container-height);
  width: 0;
  align-items: center;
  justify-content: flex-start;
  overflow: visible;
  left: var(--container-left);
  top: var(--container-top);
}

.tt-separator {
  margin: 0.5rem;
  border-bottom: 1px solid var(--background-offset);
  width: 100%;
  opacity: 0.5;
}

.tooltip-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icon-tooltip {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
}
