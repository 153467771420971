// https://css-tricks.com/styling-cross-browser-compatible-range-inputs-css/

input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
}
input[type='range']:focus {
  outline: none;
}
input[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 2rem;
  cursor: pointer;
  background: var(--background);
  border: 1px solid red;
}
input[type='range']::-webkit-slider-thumb {
  height: 2rem;
  width: 0px;
  background: red;
  cursor: pointer;
  -webkit-appearance: none;
}
input[type='range']:focus::-webkit-slider-runnable-track {
  background: var(--background);
}
input[type='range']::-moz-range-track {
  width: 100%;
  height: 2rem;
  cursor: pointer;
  background: transparent;
  border: 1px solid red;
}
input[type='range']::-moz-range-thumb {
  height: 2rem;
  width: 0px;
  background: red;
  cursor: pointer;
  border: none;
  outline: none;
}
